import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuServices, ControlServices } from '../../services';
import * as objectFitVideos from 'object-fit-videos';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent {
  @ViewChild('video', { static: false }) _video: ElementRef;
  NavigateURL = '';
  ReadMorePage = '';
  ArticleID = '';
  ArticleData: any = {};
  controls = [];

  constructor (private _route: ActivatedRoute,
    @Inject('MenuServices') private _menuServices: MenuServices,
    @Inject('ControlServices') private _controlServices: ControlServices) {

  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.NavigateURL = params['NavigateURL'];
      if (this.NavigateURL) {
        this._menuServices.getMenuControlsByNavigateURL(this.NavigateURL).subscribe((_controls) => {
          this.controls = _controls;
        });
      } else {
        this.ReadMorePage = params['ReadMorePage'];
        this.ArticleID = params['ArticleID'];
      }
    });
  }


  start() {
    (<HTMLVideoElement>this._video.nativeElement).muted = true;
    objectFitVideos();
  }

  start2() {
    (<HTMLVideoElement>this._video.nativeElement).muted = true;
    objectFitVideos();
  }

  ended() {
    // (<HTMLVideoElement>this._video.nativeElement).currentTime = 0.85;
    (<HTMLVideoElement>this._video.nativeElement).play();
  }


}
