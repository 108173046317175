import { Component, Input, Inject } from '@angular/core';


@Component({
  selector: 'app-article-new-list',
  templateUrl: './article-new-list.component.html',
  styleUrls: ['./article-new-list.component.scss']
})
export class ArticleNewListComponent {
  @Input() Article: any = {};
  @Input() ReadMoreLink = '';

  constructor() {

  }

  ngOnInit() {

  }

}
