import { Component, Input, Inject } from '@angular/core';


@Component({
  selector: 'app-article-express',
  templateUrl: './article-express.component.html',
  styleUrls: ['./article-express.component.scss']
})
export class ArticleExpressComponent {
  @Input() Article: any = {};
  @Input() ReadMoreLink = '';
  constructor() {

  }

  ngOnInit() {

  }

}
