import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { GalleryComponent } from './gallery.component';
import { GalleryBannerComponent } from './layouts/banner/gallery-banner.component';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    FlexLayoutModule
  ],
  declarations: [
    GalleryComponent,
    GalleryBannerComponent
  ],
  providers: [
  ],
  exports: [
    GalleryComponent
  ]
})
export class GalleryModule { }
