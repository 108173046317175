import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-articles-peoples',
  templateUrl: './articles-peoples.component.html',
  styleUrls: ['./articles-peoples.component.scss']
})
export class ArticlesPeoplesComponent {
  @Input() Articles = [];
  @Input() Title = '';
  @Input() Blocks = 4;
  CurrentUrl = this._router.url;
  Partners = [];
  Associates = [];
  constructor (private _router: Router) {

  }

  ngOnInit() {

  }

  ngOnChanges(e) {
    if (e.Articles && this.Articles.length > 0) {
      this.Partners = this.Articles.filter((a) => a.AlternativeText.indexOf('Partner') > -1);
      this.Associates = this.Articles.filter((a) => a.AlternativeText.indexOf('Partner') < 0);
    }
  }

}
