import { Component, Input, Inject } from '@angular/core';
import { AppServices } from 'src/app/services/app.services';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent {
  @Input() menuItems = [];

  constructor (@Inject('AppServices') private _appServices: AppServices) {

  }

  socialLink(_event) {
    if (_event.offsetX < 25) {
      window.open('https://twitter.com/tplaw_', '_blank');
    } else {
      window.open('https://www.linkedin.com/company/tplaw/', '_blank');
    }
  }

  openCookiePolicies(e) {
    this._appServices.openCookieManager.emit(true);
  }
}
