import { HostListener, Directive, ElementRef, Renderer } from '@angular/core';

@Directive({
    selector: '.header, [ios-stop-scroll]',
})
export class ioScrollFixedDirective {


    constructor (private _element: ElementRef, private renderer: Renderer) {
        const isiOSPlatform = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        const isiOSAgent = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
        if (isiOSPlatform || isiOSAgent) {
            this.renderer.setElementClass(this._element.nativeElement, 'ios-header', true);
        }
    }

    @HostListener('touchmove', ['$event']) _touchmove(_event) {
        _event.stopPropagation();
        _event.preventDefault();
        return false;
    }

    @HostListener('scroll', ['$event']) _scroll(_event) {
        _event.stopPropagation();
        _event.preventDefault();
        return false;
    }


}
