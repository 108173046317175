import { Component, Input, Inject, HostBinding } from '@angular/core';
import { Location } from '@angular/common';
import { ControlServices } from '../../../../../services';


@Component({
  selector: 'app-article-expertise',
  templateUrl: './article-expertise.component.html',
  styleUrls: ['./article-expertise.component.scss']
})
export class ArticleExpertiseComponent {
  Partners = null;
  Associates = null;
  @Input() Article: {
    ArticleID?: string
    Title?: string
    ImagePath?: string
    MobileImagePath?: string
    Content?: string
  } = {};
  @Input() PreviousUrl = '/';
  Category: { Title?: string } = {};

  constructor (@Inject('ControlServices') private _controlServices: ControlServices) {
  }

  ngOnChanges(_changes) {
    if (_changes.Article && this.Article.ArticleID) {
      this._controlServices.getControlData('ArticleCategory', this.Article.ArticleID).subscribe((_category) => {
        this.Category = _category[0];
      });
      const regEmail = /\[Email\]([\s\S]*?)\[\/Email]/gmi;
      const regContact = /\[Contact\]([\s\S]*?)\[\/Contact]/gmi;
      const _contactArray = regContact.exec(this.Article.Content);
      if (_contactArray) {
        this.Partners = _contactArray[0].replace('[Contact]', '').replace('[/Contact]', '');
        this.Partners = this.Partners.replace(regEmail, '<a href="mailto:$1">$1</a>');
      }
      const regAssociates = /\[Associates\]([\s\S]*?)\[\/Associates]/gmi;
      const _AssociatesArray = regAssociates.exec(this.Article.Content);
      if (_AssociatesArray) {
        this.Associates = _AssociatesArray[0].replace('[Associates]', '').replace('[/Associates]', '');
        this.Associates = this.Associates.replace(regEmail, '<a href="mailto:$1">$1</a>');
      }
      this.Article.Content = this.Article.Content.replace(regContact, '').replace(regAssociates, '');
    }
  }

}
