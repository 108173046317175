import { Component } from '@angular/core';

@Component({
  selector: 'app-about-cyprus',
  templateUrl: './about-cyprus.component.html',
  styleUrls: ['./about-cyprus.component.scss']
})
export class AboutCyprusComponent {

  constructor () {

  }
}
