import { Component, Input, Inject } from '@angular/core';
import { ControlServices } from '../../services';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent {
  _mqAlias = '';
  CalcRecords = 1;
  @Input() ArticleList: any = {};
  @Input() Settings: any = {
    Articles: [],
    SubArticleLists: [],
    Title: '',
    Template: '',
    Columns: 4,
    RecordToShow: 4,
    ReadMore: ''
  };

  constructor (@Inject('ControlServices') private _controlServices: ControlServices,
    private _media: MediaObserver) {

    this._media.media$.subscribe((change: MediaChange) => {
      this._mqAlias = change.mqAlias;
      this.CalculateRecords();
    });

  }

  ngOnInit() {
    if (this.Settings.Articles && this.Settings.Articles.length === 0 && this.ArticleList.ArticleListID) {
      this._controlServices.getControlData('ArticleList', this.ArticleList.ArticleListID).subscribe((_articleList) => {
        this.Settings.Articles = _articleList.Articles;
        this.Settings.Title = _articleList.Title;
        this.Settings.Template = 'article-sub-list';
      });
    }

  }

  ngOnChanges(_changes) {
    if (_changes.Settings) {
      this.CalculateRecords();
    }
  }

  CalculateRecords() {
    if (this._mqAlias === 'xs') {
      this.CalcRecords = 1;
    } else if (this._mqAlias === 'sm' && parseInt(this.Settings.Records, 0) > 1) {
      this.CalcRecords = 1;
    } else {
      this.CalcRecords = this.Settings.Records;
    }
  }



}
