import { Component, Input, Inject } from '@angular/core';
import { ControlServices } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {
  @Input() ArticleID = '';
  @Input() Layout = 'article-single';
  @Input() ReadMorePage = '';
  Article: any = {};
  CurrentUrl = this._router.url;

  get ReadMoreLink() {
    const _readmorepage = this.ReadMorePage ? this.ReadMorePage.replace('-article', '') : this.CurrentUrl;
    const _alias = this.Article.Alias ? this.Article.Alias : '@' + this.Article.ArticleID;
    return _readmorepage + '/' + _alias;
  }

  get PreviousUrl() {
    const _lastindex = this.CurrentUrl.lastIndexOf('/');
    return this.CurrentUrl.substr(0, _lastindex);
  }

  constructor (@Inject('ControlServices') private _controlServices: ControlServices
    , private _router: Router) {
  }

  ngOnChanges(_changes) {
    if (_changes.ArticleID) {
      this._controlServices.getControlData('Article', this.ArticleID).subscribe((_articleData) => {
        this.Article = _articleData[0];
      });
    }
  }


}
