import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '../../framework';
import { environment } from '../../environments/environment';


@Injectable()
export class EventServices {
    constructor (@Inject('HttpClient') private _httpClient: HttpClient) {

    }

    setEventAppliance(_data) {
        return this._httpClient.Post(environment.apiUrl + '/event', JSON.stringify(_data))
    }
}
