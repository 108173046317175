import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-articles-news',
  templateUrl: './articles-news.component.html',
  styleUrls: ['./articles-news.component.scss']
})
export class ArticlesNewsComponent {
  @Input() Articles = [];
  @Input() Title = '';
  @Input() Blocks = 4;
  CurrentUrl = this._router.url;
  constructor (private _router: Router) {

  }


  ngOnInit() {

  }

}
