import { Component, Input, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-article-people',
  templateUrl: './article-people.component.html',
  styleUrls: ['./article-people.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticlePeopleComponent {
  @Input() Article: any = {};
  @Input() ReadMoreLink: '';
  Telephone = '';
  Email = '';
  Biography = '';
  CurrentUrl = this._router.url;

  get PreviousUrl() {
    const _lastindex = this.CurrentUrl.lastIndexOf('/');
    return this.CurrentUrl.substr(0, _lastindex);
  }
  constructor(private _router: Router) {


  }

  ngOnInit() {


  }

  ngOnChanges(_changes) {
    if (_changes.Article && this.Article.Content) {
      const regEmail = /\[Email.*\/Email]/gmi;
      const _emailArray = regEmail.exec(this.Article.Content);
      if (_emailArray) {
        this.Email = _emailArray[0].replace('[Email]', '').replace('[/Email]', '');
      }
      const regTel = /\[Tel.*\/Tel]/gmi;
      const _telArray = regEmail.exec(this.Article.Content);
      if (_telArray) {
        this.Telephone = _telArray[0].replace('[Tel]', '').replace('[/Tel]', '');
      }
      this.Biography = this.Article.Content.replace(regEmail, '').replace(regTel, '');
    }
  }

}
