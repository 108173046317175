import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '../../framework';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class MenuServices {
    constructor(@Inject('HttpClient') private _httpClient: HttpClient) {

    }


    getMenuItems() {
        const _path = `${environment.apiUrl}/cms/menu-items`;
        return this._httpClient.Get(_path)
            .pipe(map((res) => res.json()));

    }

    getMenuControlsByNavigateURL(_navigateURL) {
        const _path = `${environment.apiUrl}/cms/menu-item-controls-by-navigateURL/${_navigateURL}`;
        return this._httpClient.Get(_path)
            .pipe(map((res) => res.json()));

    }
}
