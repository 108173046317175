import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent {
  @Input() ArticleList: { Title?: string };
  @Input() SubArticleLists = [];
  @Input() Articles = [];
  @Input() Title = '';
  CurrentUrl = this._router.url;
  constructor (private _router: Router) {

  }

  ngOnInit() {
  }

}
