import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gallery-banner',
  templateUrl: './gallery-banner.component.html',
  styleUrls: ['./gallery-banner.component.scss']
})
export class GalleryBannerComponent {
  @Input() EnableBullets = false;
  @Input() GalleryItems = [];
  VisibleIndex = 0;

  ngOnInit() {
    setInterval(() => {
      if (this.VisibleIndex === this.GalleryItems.length - 1) {
        this.VisibleIndex = 0;
      } else {
        this.VisibleIndex++;
      }
    }, 10000);
  }
}
