import { Component, Renderer2, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-cookie-manager',
  templateUrl: './cookie-manager.component.html',
  styleUrls: ['./cookie-manager.component.scss']
})
export class CookieManagerComponent {
  @Input() GoogleTagCode = 'G-KZ3Q7S51KN';
  cookiesPoliciesForm: FormGroup = this._fb.group({
    Necessary: [{ value: true, disabled: true }],
    Analytics: [this.isAnalyticsConsented]
  });
  closed = false;
  opened = false;
  openDetails = false;
  cookiePolicy: 'Necessary' | 'Analytics' = 'Necessary';

  get isConsented() {
    return this.getCookie('COOKIE_CONSENT') === '1';
  }

  get isAnalyticsConsented() {
    return this.getCookie('COOKIE_CONSENT_ANALYTICS') === '1';
  }



  constructor (
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private _fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.refreshCookies();
  }

  private getCookie(name: string) {
    const cookies: Array<string> = document.cookie.split(';');
    const cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < cookies.length; i += 1) {
      c = cookies[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }



  consent() {
    this.clear();
    this.setCookie('COOKIE_CONSENT', '1', 365);
    if (this.cookiesPoliciesForm.get('Analytics').value === true) {
      this.setCookie('COOKIE_CONSENT_ANALYTICS', '1', 365);
    }
    this.refreshCookies();
  }

  clear() {
    const cookies: Array<string> = document.cookie.split(';');
    for (let i: number = 0; i < cookies.length; i += 1) {
      const c = cookies[i].replace(/^\s+/g, '');
      this.deleteCookie(c);
    }
    this.close();
  }

  close() {
    this.closed = true;
    this.opened = false;
  }

  open() {
    this.opened = true;
  }

  refreshCookies() {
    this.loadGoogleAnalytics();
    this.opened = false;
  }

  loadGoogleAnalytics() {
    if (this.isAnalyticsConsented) {
      const s = this.renderer2.createElement('script');
      s.onload = this.loadGoogleAnalyticsScript.bind(this);
      s.type = 'text/javascript';
      s.src = `https://www.googletagmanager.com/gtag/js?id=${this.GoogleTagCode}`; // Defines someGlobalObject
      s.text = ``;
      this.renderer2.appendChild(this._document.body, s);
    } else {
      const cookies: Array<string> = document.cookie.split(';');
      for (let i: number = 0; i < cookies.length; i += 1) {
        const c = cookies[i].replace(/^\s+/g, '');
        if (c.indexOf('_ga') >= 0) {
          this.deleteCookie(c);
        }
      }
    }
  }

  loadGoogleAnalyticsScript() {
    const s = this.renderer2.createElement('script');
    s.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${this.GoogleTagCode}');
    gtag('config', '<GA_MEASUREMENT_ID>', { 'anonymize_ip': true });
    `;
    this.renderer2.appendChild(this._document.body, s);
  }
}
