import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material';
import { ArticlesComponent } from './articles.component';
import { ArticlesListExpressComponent } from './layouts/articles-list-express/articles-list-express.component';
import { ArticleComponent } from './article/article.component';
import { ArticleExpressComponent } from './article/layout/article-express/article-express.component';
import { ArticleSingleComponent } from './article/layout/article-single/article-single.component';
import { ArticlesListComponent } from './layouts/articles-list/articles-list.component';
import { ArticlesSubListComponent } from './layouts/articles-sub-list/articles-sub-list.component';
import { ArticlesNewsComponent } from './layouts/articles-news/articles-news.component';
import { ArticlesPeoplesComponent } from './layouts/articles-peoples/articles-peoples.component';
import { ArticlePeopleListComponent } from './article/layout/article-people-list/article-people-list.component';
import { ArticleNewListComponent } from './article/layout/article-new-list/article-new-list.component';
import { ArticleExpertiseComponent } from './article/layout/article-expertise/article-expertise.component';
import { ArticleNewComponent } from './article/layout/article-new/article-new.component';
import { ArticlePeopleComponent } from './article/layout/article-people/article-people.component';
import { ioScrollFixedDirective } from '../directives/scroll.directive';


@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    FlexLayoutModule,
    MatIconModule
  ],
  declarations: [
    ArticlesComponent,
    ArticlesListComponent,
    ArticlesListExpressComponent,
    ArticlesSubListComponent,
    ArticlesNewsComponent,
    ArticlesPeoplesComponent,
    ArticleComponent,
    ArticleSingleComponent,
    ArticleExpressComponent,
    ArticlePeopleListComponent,
    ArticleNewListComponent,
    ArticleExpertiseComponent,
    ArticleNewComponent,
    ArticlePeopleComponent,
    ioScrollFixedDirective
  ],
  providers: [
  ],
  exports: [
    ArticlesComponent,
    ArticleComponent,
    ioScrollFixedDirective
  ]
})
export class ArticlesModule { }
