import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule, MatSidenavModule, MatIconModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatButton, MatButtonModule, MatSelectModule, MatDatepickerModule, MatCheckboxModule } from '@angular/material';
import { AppComponent } from './app.component';
import { MenuModule, GalleryModule, ArticlesModule, PlaceholderComponent, ControlComponent, CookieManagerModule } from './components';
import { ServicesModule } from './services';
import { ioScrollFixedDirective } from './components/directives/scroll.directive';
import { ApplicationFormComponent } from './components/custom/application-form/application-form.component';
import { AboutCyprusComponent } from './components/custom/about-cyprus/about-cyprus.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ApplicationFormGuestComponent } from './components/custom/application-form/application-form-guest/application-form-guest.component';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'event', component: ApplicationFormComponent },
      { path: 'about-cyprus', component: AboutCyprusComponent },
      { path: ':NavigateURL', component: PlaceholderComponent },
      { path: ':ReadMorePage/:ArticleID', component: PlaceholderComponent }
    ]),
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FlexLayoutModule,
    ServicesModule,
    MenuModule,
    GalleryModule,
    ArticlesModule,
    CookieManagerModule
  ],
  declarations: [
    AppComponent,
    PlaceholderComponent,
    ControlComponent,
    ApplicationFormComponent,
    ApplicationFormGuestComponent,
    AboutCyprusComponent
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'gr' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
