import { Component, Input, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-article-new',
  templateUrl: './article-new.component.html',
  styleUrls: ['./article-new.component.scss']
})
export class ArticleNewComponent {
  @Input() Article: any = {};
  Link = '';
  Content = '';
  CurrentUrl = this._router.url;
  get PreviousUrl() {
    const _lastindex = this.CurrentUrl.lastIndexOf('/');
    return this.CurrentUrl.substr(0, _lastindex);
  }

  constructor (private _router: Router, private _sanitizer: DomSanitizer) {

  }

  ngOnInit() {

  }

  ngOnChanges(_changes) {
    if (_changes.Article && this.Article.Content) {
      const regLink = /\[Link.*\/Link]/gmi;
      const _LinkArray = regLink.exec(this.Article.Content);
      if (_LinkArray) {
        this.Link = _LinkArray[0].replace('[Link]', '').replace('[/Link]', '');
      }
      this.Content = this.Article.Content.replace(regLink, '');

      let _safeContent;
      if (this.Content && this.Content.indexOf('iframe') > 0) {
        _safeContent = this._sanitizer.bypassSecurityTrustHtml(this.Content);
        this.Content = _safeContent;
      }


    }
  }

}
