import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable()
export class AppServices {
    @Output() openCookieManager = new EventEmitter<boolean>();
    constructor () {

    }

}
