import { Component, Inject, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { MenuServices } from './services';
import { Router } from '@angular/router';
import { AppServices } from './services/app.services';
import { CookieManagerComponent } from './components/cookie-manager/cookie-manager.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // @HostListener('', ['$event']) disableBounce(event) {
  //   event.preventDefault();
  // }

  // @HostListener('window:touchmove', ['$event']) onWindowScroll(e) {
  //   debugger;
  //   console.log(e.target['scrollingElement'].scrollTop)
  // }
  @ViewChild('cookieManager', { static: true }) cookieManager: CookieManagerComponent;
  menuIconHover = false;
  menuItems = [];

  get PreviousUrl() {
    const _lastindex = this._router.url.lastIndexOf('/');
    return this._router.url.split('/').length > 1 ? this._router.url.substr(0, _lastindex) : null;
  }

  constructor (@Inject('MenuServices') private _menuServices: MenuServices
    , @Inject('AppServices') private _appServices: AppServices
    , private _router: Router) {
    this._appServices.openCookieManager.subscribe(() => {
      this.cookieManager.open();
    });
  }

  ngOnInit() {
    this._menuServices.getMenuItems().subscribe((_menuItems) => {
      this.menuItems = _menuItems;
    });
  }

  socialLink(_event) {
    if (_event.offsetX < 25) {
      window.open('https://twitter.com/tplaw_', '_blank');
    } else {
      window.open('https://www.linkedin.com/company/tplaw/', '_blank');
    }
  }



}
