import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-articles-list-express',
  templateUrl: './articles-list-express.component.html',
  styleUrls: ['./articles-list-express.component.scss']
})
export class ArticlesListExpressComponent {
  @Input() Articles = [];
  @Input() Title = '';
  @Input() Records = 4;
  @Input() Blocks = 4;
  @Input() ReadMorePage = '';

  constructor () {

  }

  ngOnInit() {

  }

}
