import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClient } from './io-Http';


@NgModule({
  imports: [
    HttpModule
  ],
  providers: [
    { provide: 'HttpClient', useClass: HttpClient }
  ]
})
export class FrameworkModule { }
