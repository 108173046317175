import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-articles-sub-list',
  templateUrl: './articles-sub-list.component.html',
  styleUrls: ['./articles-sub-list.component.scss']
})
export class ArticlesSubListComponent {
  @Input() SubArticleLists = [];
  @Input() Articles = [];
  @Input() Title = '';
  @Input() RecordsPerBlock = 13;
  @Input() ReadMoreLink = this._router.url;
  ArticlesChunks = [];

  get Blocks() {
    return new Array(Math.ceil(this.Articles.length / this.RecordsPerBlock));
  }

  constructor (private _router: Router) {

  }

  ngOnInit() {
    const _chunks = this.Articles.length / this.RecordsPerBlock;
    for (let i = 0; i < _chunks; i++) {
      const _chunk = this.GetChunkArticles(i);
      this.ArticlesChunks.push([_chunk]);
    }
  }

  GetChunkArticles(c) {
    const temp = [];
    for (let index = c * this.RecordsPerBlock; index < this.Articles.length && index < (c + 1) * this.RecordsPerBlock; index++) {
      temp.push(this.Articles[index]);
    }
    return temp;
  }

}
