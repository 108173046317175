import { NgModule } from '@angular/core';
import { FrameworkModule } from '../../framework';
import { MenuServices } from './menu.services';
import { ControlServices } from './controls.services';
import { EventServices } from './event.services';
import { AppServices } from './app.services';


@NgModule({
  imports: [
    FrameworkModule
  ],
  providers: [
    { provide: 'MenuServices', useClass: MenuServices }
    , { provide: 'ControlServices', useClass: ControlServices }
    , { provide: 'EventServices', useClass: EventServices }
    , { provide: 'AppServices', useClass: AppServices }
  ]
})
export class ServicesModule { }
