import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-article-single',
  templateUrl: './article-single.component.html',
  styleUrls: ['./article-single.component.scss']
})
export class ArticleSingleComponent {
  @Input() Article: any = {};
  constructor (private _sanitizer: DomSanitizer) {
  }

  ngOnInit() {

  }

  GetContent(_content) {
    let _safeContent = _content;
    if (_content && _content.indexOf('iframe') > 0) {
      _safeContent = this._sanitizer.bypassSecurityTrustHtml(_content);
    }

    return !_safeContent ? '' : _safeContent;
  }

}
