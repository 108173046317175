import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '../../framework';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


export type TControlType = 'ArticleList' | 'Article' | 'ArticleCategory' | 'Gallery';

@Injectable()
export class ControlServices {

    constructor(@Inject('HttpClient') private _httpClient: HttpClient) {

    }


    getControlSettings(_controlType: TControlType, _ControlID) {
        const _path = `${environment.apiUrl}/cms/control/${_controlType}/${_ControlID}`;
        return this._httpClient.Get(_path)
            .pipe(map((res) => res.json()));

    }

    getControlData(_controlType: TControlType, _ControlID) {
        const _path = `${environment.apiUrl}/cms/${_controlType}/${_ControlID}`;
        return this._httpClient.Get(_path)
            .pipe(map((res) => res.json()));

    }

}
