import { Component, Input, Inject } from '@angular/core';


@Component({
  selector: 'app-article-people-list',
  templateUrl: './article-people-list.component.html',
  styleUrls: ['./article-people-list.component.scss']
})
export class ArticlePeopleListComponent {
  @Input() Article: any = {};
  @Input() ReadMoreLink: '';
  constructor() {
  }

  ngOnInit() {

  }

}
