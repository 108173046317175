import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieManagerComponent } from './cookie-manager.component';
import { MatButtonModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule
  ],
  declarations: [
    CookieManagerComponent
  ],
  providers: [
  ],
  exports: [
    CookieManagerComponent
  ]
})
export class CookieManagerModule { }
