// import { Observable } from 'rxjs';
// import { Injectable, Inject } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';

import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

@Injectable()
export class HttpClient {

    constructor(private _http: Http) { }

    private setAuthorizationHeader(headers: Headers) {
        headers.append('Token', localStorage.getItem('id_token'));
    }

    public Get(url, _defaultHeader = true) {
        const _headers = new Headers();
        if (_defaultHeader) {
            this.setAuthorizationHeader(_headers);
        }
        return this._http.get(url, {
            headers: _headers
        });
    }

    public Post(url, data: string, _contentType = 'application/json') {
        const _headers = new Headers();
        _headers.append('Content-Type', _contentType);
        this.setAuthorizationHeader(_headers);
        return this._http.post(url, data, {
            headers: _headers
        });
    }

    public Put(url, data: string, _contentType = 'application/json') {
        const _headers = new Headers();
        _headers.append('Content-Type', _contentType);
        this.setAuthorizationHeader(_headers);
        return this._http.put(url, data, {
            headers: _headers
        });
    }

    public Delete(url) {
        const _headers = new Headers();
        this.setAuthorizationHeader(_headers);
        return this._http.delete(url, {
            headers: _headers
        });
    }

    public Upload(url: string, files: File[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            for (let i = 0; i < files.length; i++) {
                formData.append('uploads[]', files[i], files[i].name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', localStorage.getItem('id_token'));
            xhr.send(formData);
        });
    }

    public XMLResponseToJSON(_res) {
        const _parser = new DOMParser();
        const _xmlDoc = _parser.parseFromString(_res.text(), 'text/xml');
        const _resText = (<any>_xmlDoc.childNodes.item(0)).innerHTML;
        return JSON.parse(_resText);
    }
}
