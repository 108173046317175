import { Component, Inject, Input, ChangeDetectionStrategy } from '@angular/core';
import { ControlServices } from '../../../services';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent {
  @Input() Control;
  ControlSettings: {
    ArticleID?: string,
    GalleryItems?: string
  } = {};

  constructor (@Inject('ControlServices') private _controlServices: ControlServices) {

  }

  ngOnInit() {
    this._controlServices.getControlSettings(this.Control.ControlType, this.Control.ItemControlID)
      .subscribe((_data) => {
        if (_data instanceof Array) {
          this.ControlSettings = _data[0];
        } else {
          this.ControlSettings = _data || {};
        }
      });
  }


}
