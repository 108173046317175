import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-application-form-guest',
  templateUrl: './application-form-guest.component.html',
  styleUrls: ['./application-form-guest.component.scss']
})
export class ApplicationFormGuestComponent {
  @Output() removeParticipant = new EventEmitter<number>();
  @Input() participantForm;
  @Input() i;

  constructor () {

  }



}